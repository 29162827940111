'use client';

import { useState } from 'react';
import clsx from 'clsx';

import { sendLog } from '@/services/logs';

import Icon from '../Icon';

import styles from './styles.module.scss';

export default function Newsletter() {
  const [hasSubscribe, setHasSubscribe] = useState(false);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        const email =
          'email' in e.target &&
          typeof e.target.email === 'object' &&
          !!e.target.email &&
          'value' in e.target.email &&
          typeof e.target.email.value === 'string' &&
          e.target.email.value;

        sendLog('app_newsletter_subscription', { email });
        setHasSubscribe(true);
      }}
      className={styles.formContainer}
    >
      <div className={styles.formBody}>
        <h2 className={clsx('bodyStrong', styles.catchphrase)}>
          Pick up your custom photo order same day at your local Walgreens or
          Walmart with Picta!
        </h2>
        <h3 className={clsx('bodyStronger', styles.title)}>
          Join our Newsletter
        </h3>
        <p className="footnoteMedium">
          Stay in the know and get 20% off your first photo order!
        </p>
      </div>
      <div className={styles.emailInputWrapper}>
        {hasSubscribe ? (
          <span className={styles.successMessage}>
            You&apos;re in! Keep an eye out for us in your inbox! 👀
          </span>
        ) : (
          <>
            <button
              type="submit"
              className={styles.emailSubmit}
              aria-label="subsribe to newsletter"
            >
              <Icon name="circleArrow" className={styles.emailSubmitIcon} />
            </button>

            <input
              id="email"
              className={styles.emailInput}
              type="email"
              placeholder="Email"
              required
            />
          </>
        )}
      </div>
    </form>
  );
}
